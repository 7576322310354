import React from 'react';
import IframeComponent from '../../components/iframe';

const IframePage = () => (
  <IframeComponent
    src='https://mernstackrestaurant.netlify.app'
    background='#8e7aae'
    item={0}
  />
);

export default IframePage;
